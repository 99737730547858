<template>
    <div stripe border v-loading="loading"
         element-loading-text = "加载中,请稍后..."
         element-loading-background="rgba(0, 0, 0, 0.7)">
        <mt>
             <el-button  class="funBtn"  @click="onCreate" v-if="isAdmin" >添加商品</el-button>
        </mt>
        <el-card style="margin: 30px" shadow="always">
            <div slot="header" class="clearfix">
                <span style="font-size: 26px">商品</span>
            </div>
            <el-table  :data="goods" class="funBtn" height="580px" >
                <el-table-column label="NO." width="80" type="index"></el-table-column>
                <el-table-column label="商品名称" prop="name" min-width="120"></el-table-column>
                <el-table-column label="条码" prop="bar"></el-table-column>
                <el-table-column label="简称" prop="shortName"></el-table-column>
                <el-table-column label="规格" prop="specs"></el-table-column>
                <el-table-column label="单位" prop="unit"></el-table-column>
                <el-table-column label="默认零售价" prop="price1">
                    <template slot-scope="scope">
                        {{scope.row.price1 | fen2yuan}}
                    </template>
                </el-table-column>
                <el-table-column label="默认优惠价" prop="price2">
                    <template slot-scope="scope">
                        {{scope.row.price2 | fen2yuan}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="210">
                    <template slot-scope="scope">
                        <el-button  @click="statistic(scope.row)">销量统计</el-button>
                        <el-button  @click="onEdit(scope.row)" v-if="isAdmin">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pager">
                <el-pagination
                        @size-change="onQuery"
                        @current-change="onQuery"
                        :current-page.sync="param.page"
                        :page-sizes="[50,100]"
                        :page-size.sync="param.size"
                        layout="sizes, prev, pager, next,total"
                        :total="param.total">
                </el-pagination>
            </div>
            <el-dialog :visible.sync="showDialog" width="500px" title="添加/编辑商品">
                <el-form label-width="160px" :model="newGoods" :rules="rules" ref="newGoods" >
                    <el-form-item label="商品名称" prop="name">
                        <el-input v-model="newGoods.name" style="width: 200px"></el-input>
                    </el-form-item>
                    <el-form-item label="条码" prop="bar">
                        <el-input v-model="newGoods.bar"  style="width: 200px"></el-input>
                    </el-form-item>
                    <el-form-item label="简称" prop="shortName">
                        <el-input v-model="newGoods.shortName"  style="width: 200px"></el-input>
                    </el-form-item>
                    <el-form-item label="规格" prop="specs">
                        <el-input v-model="newGoods.specs"  style="width: 200px"></el-input>
                    </el-form-item>
                    <el-form-item label="单位" prop="unit">
                        <el-input v-model="newGoods.unit"  style="width: 200px"></el-input>
                    </el-form-item>
                    <el-form-item label="默认零售价" prop="price1">
                        <el-input-number v-model="newGoods.price1" :precision="2" :controls="false"></el-input-number>
                        <span style="font-size: 20px">  元/{{newGoods.unit||'个'}}</span>
                    </el-form-item>
                    <el-form-item label="默认优惠价" prop="price2">
                        <el-input-number v-model="newGoods.price2" :precision="2" :controls="false"></el-input-number>
                        <span  style="font-size: 20px">  元/{{newGoods.unit||'个'}}</span>
                    </el-form-item>

                </el-form>
                <div slot="footer">
                    <el-button type="primary" @click="onCreateOk" >确定</el-button>
                    <el-button @click="onCreateCancel">取消</el-button>
                </div>
            </el-dialog>
        </el-card>
    </div>

</template>

<script>
    import mt from "@/components/MainTop.vue"
    export default {
        name: "Goods",
        components:{mt},
        data() {
            return {
                loading:true,
                param:{
                    page:1,
                    size:50,
                    total:0
                },
                showDialog: false,
                newGoods: {
                    id: "",
                    name: "",
                    bar: "",
                    specs: "",
                    unit: "",
                    price1: 0,
                    price2: 0
                },
                goods: [],
                rules: {
                    name: [{required: true, message: '请输入商品名称', trigger: 'blur'}],
                    price1: [{required: true, message: '请输入默认零售价', trigger: 'blur'},
                        {type: "number", message: '请输入正确的价格', trigger: 'blur'}],
                    price2: [{required: true, message: '请输入默认优惠价', trigger: 'blur'},
                        {type: "number", message: '请输入正确的价格', trigger: 'blur'}],
                }
            }
        },
        mounted() {
            this.onQuery();
        },
        methods: {
            onQuery() {
                let _this = this;
                this.loading = true,
                this.$http.get("/api/goods/find",{params:this.param})
                    .then(rsp => {
                        if (rsp.data.status == 200) {
                            _this.goods = rsp.data.data || [];
                            _this.param.total=rsp.data.count;
                            this.loading = false

                        }
                    })
            },
            statistic(row) {
                this.$router.push({name:"GoodsStatistic",params:{form:row}},)
            },
            onCreate() {
                this.showDialog = true;
            },
            onCreateCancel() {
                this.showDialog = false;
                this.clearData();
            },
            onCreateOk() {

                this.$refs['newGoods'].validate((valid) => {
                        if (!valid) {
                            return
                        }
                        let param = JSON.parse(JSON.stringify(this.newGoods))
                        param.price1 = this.yuan2fen(this.newGoods.price1);
                        param.price2 = this.yuan2fen(this.newGoods.price2);
                        if (this.newGoods.id) {//编辑
                            this.$http.patch("/api/goods/edit/" + param.id, param)
                                .then((rsp) => {
                                    if (rsp.data.status == 200) {
                                        this.$message.success("编辑成功");
                                        this.showDialog = false;
                                        this.clearData();
                                        this.onQuery();
                                    }
                                }).catch((rsp) => {
                                    this.$message.error(rsp.response.data.message);

                                })
                        } else {//新建
                             this.$http.post("/api/goods/create", param)
                                .then((rsp) => {
                                    if (rsp.data.status == 200) {
                                        this.$message.success("创建成功");
                                        this.showDialog = false;
                                        this.clearData();
                                        this.onQuery();
                                    }
                                })
                                .catch((rsp) => {
                                    this.$message.error(rsp.response.data.message);
                                })

                        }
                    }
                );

            },
            onEdit(row) {
                this.clearData();
                this.newGoods = JSON.parse(JSON.stringify(row));
                this.newGoods.price1 = this.fen2yuan(this.newGoods.price1);
                this.newGoods.price2 = this.fen2yuan(this.newGoods.price2);
                this.showDialog = true;
            },

            clearData() {
                this.newGoods = {
                    id: "",
                    name: "",
                    bar: "",
                    shortName: "",
                    specs: "",
                    unit: "",
                    price1: 0,
                    price2: 0
                };
            }
        }
    }
</script>

<style scoped>

</style>
